// src/components/AethericAI/DevGraphNavigator/graph/ticked.js

import * as d3 from "d3";

export function ticked(svgRef) {
  if (!svgRef.current) return;
  const svg = d3.select(svgRef.current);
  const svgGroup = svg.select(".graph-group");
  const linksLayer = svgGroup.select(".links-layer");
  const nodesLayer = svgGroup.select(".nodes-layer");

  // 1) Update node positions
  nodesLayer
    .selectAll(".node")
    .attr("transform", (d) => `translate(${d.x},${d.y})`);
    // .each(function (d) {
    //   // Log each node’s latest x/y (and fx/fy if pinned)
    //   console.log(
    //     `[ticked] Node ID=${d.id}`,
    //     `x=${d.x?.toFixed(1)}`, // optional rounding
    //     `y=${d.y?.toFixed(1)}`,
    //     `fx=${d.fx}`, 
    //     `fy=${d.fy}`
    //   );
    // });

  // 2) Update link positions
  //    - Each link is a <g.link> with a child <line.link-line>
  //    - Then ephemeral icons are <text.ephemeral-link-icon> inside <g.link-buttons>

  const linkGroup = linksLayer.selectAll("g.link");

  // (A) Position the <line>
  linkGroup.select("line.link-line")
    .attr("x1", (d) => d.source.x)
    .attr("y1", (d) => d.source.y)
    .attr("x2", (d) => d.target.x)
    .attr("y2", (d) => d.target.y);

  // (B) Position the ephemeral icons
  //     We'll place them near the midpoint (mx, my) of the link, plus optional offset
  linkGroup.selectAll("text.ephemeral-link-icon")
    .attr("x", function() {
      // The link <g> has datum = { source, target, ... }
      // but we can select it from the parent
      const lineData = d3.select(this.parentNode.parentNode).datum();
      if (!lineData || !lineData.source || !lineData.target) return 0;

      const mx = (lineData.source.x + lineData.target.x) / 2;
      // read the offset from attribute
      const offsetX = +d3.select(this).attr("__offsetX") || 0;
      return mx + offsetX;
    })
    .attr("y", function() {
      const lineData = d3.select(this.parentNode.parentNode).datum();
      if (!lineData || !lineData.source || !lineData.target) return 0;

      const my = (lineData.source.y + lineData.target.y) / 2;
      const offsetY = +d3.select(this).attr("__offsetY") || 0;
      return my + offsetY;
    });


}