// src/components/AethericAI/DevGraphNavigator/graph/initializeSVG.js
import * as d3 from "d3";

export function initializeSVG(svgRef, svgSelection) {
  // 1) Store the actual <svg> DOM node in svgRef.current
  //    => So we can do svgRef.current.getBoundingClientRect()
  svgRef.current = svgSelection.node();

  // 2) Create the group layers
  const svgGroup = svgSelection.append("g").attr("class", "graph-group");
  svgGroup.append("g").attr("class", "links-layer");
  svgGroup.append("g").attr("class", "nodes-layer");

  // 3) The D3 zoom function
  const zoom = d3.zoom()
    .scaleExtent([0.1, 4])
    .on("zoom", (event) => {
      svgGroup.attr("transform", event.transform);
    });

  // 4) Attach the zoom behavior to the <svg> selection
  svgSelection.call(zoom);

  // 5) Store the zoom function on a custom property
  //    => This is how we programmatically pan/zoom later
  svgRef.current.__zoomBehavior = zoom;

  // 6) (Optional) disable double-click zoom
  svgSelection.on("dblclick.zoom", null);

  console.log("[initializeSVG] DOM node =>", svgRef.current);
  console.log("[initializeSVG] Zoom function =>", zoom);
}