// src/components/AethericAI/DevGraphNavigator/hook/handleRelationshipCreation.js

import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_RELATIONSHIP } from "../../../../graphql/Graph/graph_mutations";

const useHandleRelationshipCreation = () => {
    const [createRelationshipMutation, { data: relData, error: relError }] =
    useMutation(CREATE_RELATIONSHIP);

  const handleCreateRelationship = useCallback(
    async (relationshipInput) => {
      try {
        await createRelationshipMutation({ variables: { input: relationshipInput } });
        console.log("Relationship created successfully!");
      } catch (err) {
        console.error("Error creating relationship:", err);
      }
    },
    [createRelationshipMutation]
  );

  return {
    handleCreateRelationship,
    relData,
    relError,
  };
};

export default useHandleRelationshipCreation;