// src/components/AethericAI/DevGraphNavigator/graph/updateLinks.

import * as d3 from "d3";
import AuthContext from "../../../../contexts/AuthContext";


// A file-level variable to track which <g> link group is currently open:
let currentlyOpenLinkGroup = null;

/**
 * Show ephemeral icons for the provided link group, hiding them on any previously open link group.
 */
function showLinkIcons(linkGroupSelection) {
  if (currentlyOpenLinkGroup && currentlyOpenLinkGroup !== linkGroupSelection) {
    // Hide ephemeral icons on the previously open link group
    currentlyOpenLinkGroup.select(".link-buttons").style("display", "none");
  }
  linkGroupSelection.select(".link-buttons").style("display", "inline");
  currentlyOpenLinkGroup = linkGroupSelection;
}

/**
 * Hide ephemeral icons from the provided link group
 */
function hideLinkIcons(linkGroupSelection) {
  linkGroupSelection.select(".link-buttons").style("display", "none");
  if (currentlyOpenLinkGroup === linkGroupSelection) {
    currentlyOpenLinkGroup = null;
  }
}

/**
 * updateLinks
 *  - Renders a <g class="link"> that wraps each line
 *  - Attaches ephemeral icons (edit/delete, etc.)
 *  - Attaches pointer events for showing/hiding ephemeral icons
 *  - Merges old + new
 */
export function updateLinks({
  linksLayer,
  linksData,
  handleDeleteRelationship, // callback for '🗑' icon
  onOpenEditRelModal,       // callback for '✏' icon
  svgRef,
  user                    // so we can add a pointerdown on the <svg> to hide ephemeral icons
}) {
  // 1) Data join
  const link = linksLayer.selectAll(".link").data(linksData, (d) => d.id);

  // 2) EXIT => remove old
  link.exit().remove();

  // 3) ENTER => each link is now a <g> container
  const linkEnter = link
    .enter()
    .append("g")
    .attr("class", "link")
    // We'll show ephemeral icons on pointerdown (like you do for nodes)
    .on("pointerdown", function (event, d) {
      event.stopPropagation(); // Don’t bubble to background
      showLinkIcons(d3.select(this));
    });

  // a) Inside the <g>, we append our <line> to represent the link
  linkEnter
    .append("line")
    .attr("class", "link-line")
    .attr("stroke", "#20212b")
    .attr("stroke-width", 1.5);

  // b) Add a sub-<g> for ephemeral relationship icons
  const buttonGroupEnter = linkEnter
    .append("g")
    .attr("class", "link-buttons")
    .style("display", "none"); // hidden by default

  // We define ephemeral icons for each link, e.g. "edit" or "remove"
  const ephemeralButtons = [
    {
      name: "editRel",
      icon: "✏",
      offsetX: 0,
      offsetY: -12,
      allowedRoles: ["ADMIN", "EDITOR"], 
      onClick: (event, d) => {
        event.stopPropagation();
        onOpenEditRelModal?.(d);
      },
    },
    {
      name: "removeRel",
      icon: "🗑",
      offsetX: 0,
      offsetY: 12,
      allowedRoles: ["ADMIN", "EDITOR"], 
      onClick: (event, d) => {
        event.stopPropagation();
        if (window.confirm(`Delete relationship '${d.id}'?`)) {
          handleDeleteRelationship?.(d.id);
        }
      },
    },
  ];

  // c) For each ephemeral button, append <text>
  ephemeralButtons
  .filter((btn) => {
    // If no allowedRoles property, assume it's allowed for all
    if (!btn.allowedRoles) return true;
    // Otherwise check user.role
    return btn.allowedRoles.includes(user?.role);
  })
  .forEach((btn) => {
    buttonGroupEnter
      .append("text")
      .attr("class", "ephemeral-link-icon")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .attr("font-size", 12)
      .attr("fill", "#ddd")
      .attr("cursor", "pointer")
      .text(btn.icon)
      .attr("__offsetX", btn.offsetX)
      .attr("__offsetY", btn.offsetY)
      .on("click", btn.onClick);
  });

  // 4) MERGE => old + new
  const linkMerged = linkEnter.merge(link);

  // 5) If you have a force simulation with a "tick" => you will:
  //    update the line positions: x1,y1 => source.x,source.y; x2,y2 => target.x,target.y
  //    also reposition ephemeral icons
  //    For example (some snippet):
  // linkMerged.select(".link-line")
  //   .attr("x1", d => d.source.x)
  //   .attr("y1", d => d.source.y)
  //   .attr("x2", d => d.target.x)
  //   .attr("y2", d => d.target.y);

  // 6) In the same tick, you can do something like:
  // linkMerged.selectAll(".ephemeral-link-icon")
  //   .attr("x", function(d) {
  //     const lineData = d3.select(this.parentNode.parentNode).datum();
  //     // compute midpoint
  //     const mx = (lineData.source.x + lineData.target.x) / 2;
  //     const my = ...
  //     const offsetX = +d3.select(this).attr("__offsetX");
  //     // apply offset if you want
  //     return mx + offsetX;
  //   })
  //   .attr("y", ...);

  // 7) Finally, attach a pointerdown on <svg> => hide ephemeral icons if user taps background
  if (svgRef?.current) {
    d3.select(svgRef.current).on("pointerdown.hideLinkIcons", (event) => {
      if (!currentlyOpenLinkGroup) return;

      const isInsideGroup =
        event.composedPath &&
        event.composedPath().includes(currentlyOpenLinkGroup.node());
      if (!isInsideGroup) {
        hideLinkIcons(currentlyOpenLinkGroup);
      }
    });
  }
}