import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { buildSystemContext } from "../utils/buildSystemContext";
import { AI_REQUEST } from "../../../../graphql/Graph/graph_queries";

const AiFooter = ({
  user, 
  selectedNodes,
  onAiResponse,
  handleSelectNode,
  handleRemoveNode,
  zoomToNode,
}) => {
  const [prompt, setPrompt] = useState("");

  // Keep the last text we processed so we skip duplicates
  const [lastAiText, setLastAiText] = useState("");

  // 1) Lazy query for AI_REQUEST
  const [callAiRequest, { data, loading, error }] = useLazyQuery(AI_REQUEST);

  // 2) Single effect => skip duplicates & call onAiResponse
  useEffect(() => {
    // If there's no query data, do nothing
    if (!data?.aiRequest) return;

    // If it's the same text we already processed, skip
    if (data.aiRequest === lastAiText) {
      return;
    }
    // Otherwise store it & pass up
    setLastAiText(data.aiRequest);
    onAiResponse?.(data.aiRequest);

    // No second effect needed
  }, [data, onAiResponse, lastAiText]);

  // Only show if we have selected nodes
  if (!selectedNodes || selectedNodes.length === 0) {
    return null;
  }

  // 3) The form submission => calls the AI
  const handleSubmit = (e) => {
    e.preventDefault();
    const systemContent = buildSystemContext(selectedNodes);
    callAiRequest({ variables: { systemContent, userContent: prompt } });
  };

  return (
    <footer className="bg-gray-900 text-white p-2 w-full">
      {/* 4) Display "Thinking..." & errors => same as you had before */}
      {loading && <div className="text-sm text-gray-300 mb-1">Thinking…</div>}
      {error && <div className="text-sm text-red-400 mb-1">Error: {error.message}</div>}

      {/* Selected Nodes row => same as your old code */}
      <div className="mb-2">
        <h3 className="text-sm font-semibold mb-1">Selected Nodes:</h3>
        <div className="flex flex-wrap gap-1">
          {selectedNodes.map((node) => (
            <button
              key={node.id}
              type="button"
              onClick={() => {
                // Call user's "select" logic if needed
                handleSelectNode?.(node);
                // Then zoom
                if (zoomToNode && node.id) {
                  zoomToNode(node.id);
                }
              }}
              
              onDoubleClick={() => handleRemoveNode?.(node)}
              className="
                px-2 py-1 text-xs bg-gray-700 hover:bg-gray-600
                rounded whitespace-nowrap overflow-hidden text-ellipsis max-w-[200px]
              "
              title="Click to open | Double-click to remove"
            >
              {node.name || "Unnamed Node"}
            </button>
          ))}
        </div>
      </div>
      
      {/* Prompt form => same as your old code */}
      {(user?.role === "ADMIN" || user?.role === "EDITOR") && (
      <form onSubmit={handleSubmit} className="flex items-center space-x-2">
        <textarea
          id="ai-prompt"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          className="flex-grow p-1 text-sm bg-gray-800 border border-gray-700 rounded resize-none h-10"
          placeholder="Ask AI about the selected node(s)"
          rows={1}
        />
        <button
          type="submit"
          className="px-3 py-1 text-sm bg-gray-700 hover:bg-gray-600 text-white rounded flex-shrink-0"
        >
          Ask AI
        </button>
      </form>
      )}
    </footer>
  );
};

export default AiFooter;