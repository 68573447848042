// src/graphql/Graph/graph_mutations.js
import { gql } from '@apollo/client';


export const CREATE_NODE = gql`
  mutation CreateNode($input: NodeInput!) {
    createNode(input: $input) {
      nodes {
        id
        label
        name
        description
        tags
        privacy_status
        status
        properties
        createdAt
        updatedAt
      }
      relationships {
        id
        type
        source
        target
        properties
        createdAt
        updatedAt
      }
    }
  }
`;

export const CREATE_RELATIONSHIP = gql`
  mutation CreateRelationship($input: RelationshipInput!) {
    createRelationship(input: $input) {
      id
      type
      source
      target
      properties
      createdAt
    }
  }
`;

export const UPDATE_NODE = gql`
  mutation UpdateNode($id: ID!, $input: NodeInput!) {
    updateNode(id: $id, input: $input) {
      nodes {
        id
        label
        name
        description
        tags
        privacy_status
        status
        properties
        createdAt
        updatedAt
      }
      relationships {
        id
        type
        source
        target
        properties
        createdAt
        updatedAt
      }
    }
  }
`;

export const DELETE_NODE = gql`
  mutation DeleteNode($id: ID!) {
    deleteNode(id: $id) {
      success
      message
      deletedId
    }
  }
`;

//
// ──────────────────────────────────────────────────────────────────────
// HIGHLIGHT: NEW Mutations for updateRelationship & deleteRelationship
// ──────────────────────────────────────────────────────────────────────
//

/** 
 * updateRelationship
 *   - If your GraphQL schema accepts UpdateRelationshipInput 
 *     that only includes fields like (type, description),
 *     be sure to match that input name. 
 */
export const UPDATE_RELATIONSHIP = gql`
  mutation UpdateRelationship($id: ID!, $input: UpdateRelationshipInput!) {
    updateRelationship(id: $id, input: $input) {
      id
      type
      source
      target
      properties
      createdAt
      updatedAt
    }
  }
`;

/** 
 * deleteRelationship
 *   - If your schema returns a BooleanResponse 
 *     with success, message, deletedId, do the same here.
 */
export const DELETE_RELATIONSHIP = gql`
  mutation DeleteRelationship($id: ID!) {
    deleteRelationship(id: $id) {
      success
      message
      deletedId
    }
  }
`;