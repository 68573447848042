import * as d3 from "d3";

export function zoomToNode(
  svgRef,
  graphData,
  nodeId,
  scaleFactor = 1.5,
  width,
  height
) {
  if (!graphData?.nodes?.length || !svgRef.current) return;

  const svgNode = svgRef.current;      // The <svg> DOM node
  const zoomBehavior = svgNode.__zoomBehavior; // The d3.zoom() instance
  if (!zoomBehavior) return;

  // 1) Find the node => check pinned coords first
  const nodeData = graphData.nodes.find((n) => n.id === nodeId);
  if (!nodeData) return;

  const x = nodeData.fx ?? nodeData.x;
  const y = nodeData.fy ?? nodeData.y;
  if (x == null || y == null) {
    console.warn(`[zoomToNode] node ${nodeId} has no valid x,y => ignoring`);
    return;
  }

  // 2) If no width/height => measure boundingRect
  let measuredWidth = width;
  let measuredHeight = height;
  if (!measuredWidth || !measuredHeight) {
    const rect = svgNode.getBoundingClientRect();
    measuredWidth = rect.width;
    measuredHeight = rect.height;
  }

  // 3) Standard “center on (x,y)” transform
  const transform = d3.zoomIdentity
    .translate(measuredWidth / 2, measuredHeight / 2)
    .scale(scaleFactor)
    .translate(-x, -y);

  // 4) Animate
  d3.select(svgNode)
    .transition()
    .duration(3000)
    .call(zoomBehavior.transform, transform);
}