import * as d3 from "d3";
import { wrapText } from "../utils/wrapText";
import { getNodeWeight } from "./utils/getNodeWeight";
import AuthContext from "../../../../contexts/AuthContext";


/** 
 * A file-level variable to track which <g> node currently 
 * has ephemeral buttons shown (for toggling/hiding).
 */
let currentlyOpenGroup = null;

/**
 * reapplyNodeLabels:
 *   Removes old node-label text and applies multi-line text wrapping using `wrapText`.
 *   - initialNodeId => Node ID for “initialNode” (bigger radius)
 *   - rootNodeId    => Node ID for “rootNode”   (medium radius)
 */
function reapplyNodeLabels(selection, { wrapTextFn, initialNodeId, rootNodeId }) {
  selection.each(function (d) {
    // Remove any existing node-label text so we can re-draw
    d3.select(this).selectAll("text.node-label").remove();

    // Decide the radius for text wrapping
    const radius =
      d.id === initialNodeId
        ? 60  // bigger radius for initial node
        : d.id === rootNodeId
        ? 50  // medium radius for root node
        : 40; // default radius

    const maxTextWidth = radius * 2 - 8;

    const { lines, fontSize, lineHeight } = wrapTextFn(
      d.name,
      maxTextWidth,
      "12px sans-serif",
      radius
    );

    // Create <text> element (with <tspan> lines)
    const textElement = d3.select(this)
      .append("text")
      .attr("class", "node-label")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .style("fill", "#ffffff")
      .style("font-size", `${fontSize}px`)
      .style("pointer-events", "none"); // clicks pass through

    const totalHeight = lines.length * lineHeight;
    const startY = -totalHeight / 2 + lineHeight / 2;

    lines.forEach((line) => {
      textElement
        .append("tspan")
        .attr("x", 0)
        .attr("y", startY + line.y)
        .text(line.text);
    });
  });
}

/**
 * showEphemeralIcons: displays ephemeral icons for this node, hiding them
 * from any previously opened node group.
 */
function showEphemeralIcons(groupSelection) {
  if (currentlyOpenGroup && currentlyOpenGroup !== groupSelection) {
    currentlyOpenGroup.select(".node-buttons").style("display", "none");
  }
  groupSelection.select(".node-buttons").style("display", "inline");
  currentlyOpenGroup = groupSelection;
}

/**
 * hideEphemeralIcons: hides ephemeral icons for the specified node group,
 * resetting our `currentlyOpenGroup` if it was the same group.
 */
function hideEphemeralIcons(groupSelection) {
  groupSelection.select(".node-buttons").style("display", "none");
  if (currentlyOpenGroup === groupSelection) {
    currentlyOpenGroup = null;
  }
}

/**
 * updateNodes:
 *   Draws/updates:
 *    - Node circles (invisible & visible)
 *    - Ephemeral icon buttons
 *    - Labels (via `wrapText`)
 *    - Drag logic
 *    - Hides ephemeral icons on background click
 */
export function updateNodes({
  user,
  nodesLayer,           // <g> selection for the nodes
  nodesData,            // array of node data
  initialNodeId,        // special "initial" node ID => bigger radius
  rootNodeId,           // special "root" node ID => medium radius
  selectedNodeIds,      // array of selected node IDs => changes stroke color
  panelOpenNodeId,      // node ID that might get a thick stroke if open
  onClick,              // single-click => triggers expansions
  onDoubleClick,        // double-click => possibly other logic
  simulationRef,        // ref to the d3 force simulation
  onSetPinnedPosition,  // store pinned positions (localStorage, etc.)
  handleDeleteNode,     // callback to delete node
  onOpenEditModal,      // open "edit node" modal
  onOpenCreateModal,    // open "create node" modal
  originNodeIdRef,      // ref for relationship source, if used
  svgRef,               // main <svg> for global pointerdown
  relationshipSourceId, // current "source" node ID for relationship creation
  setRelationshipSourceId,
  relationshipSourceRef,
}) {


  // 1) Data JOIN
  const node = nodesLayer
    .selectAll(".node").data(nodesData, (d) => d.id); 

  // 2) EXIT => remove old
  node.exit().remove();

  // 3) ENTER => create new node <g> elements
  const nodeEnter = node.enter()
    .append("g")
    .attr("class", "node")
    // Single & double-click => your logic
    .on("click", (event, d) => onClick(d))
    .on("dblclick", (event, d) => onDoubleClick(d))
    // pointerdown => show ephemeral icons
    .on("pointerdown", function (event) {
      event.stopPropagation(); 
      showEphemeralIcons(d3.select(this));
    });

  // a) Big invisible circle => large hitbox
  nodeEnter
    .append("circle")
    .attr("class", "invisible-circle")
    .attr("r", (d) => {
      const w = getNodeWeight(d);
      if (d.id === initialNodeId) return 60;
      if (d.id === rootNodeId)    return 50;
      return 40 + 30 / w; 
    })
    .attr("fill", "transparent")
    .on("click", (event, d) => onClick(d))
    .on("dblclick", (event, d) => onDoubleClick(d));

  // b) The visible circle
  nodeEnter
    .append("circle")
    .attr("class", "visible-circle")
    .attr("r", (d) => {
      if (d.id === initialNodeId) return 60;
      if (d.id === rootNodeId)    return 50;
      return 40; 
    });

  // c) A <g> for ephemeral buttons (hidden by default)
  const buttonGroupEnter = nodeEnter
    .append("g")
    .attr("class", "node-buttons")
    .style("display", "none");

  /**
   * ephemeralButtons => we define them INSIDE updateNodes so each render
   * has the latest relationshipSourceId, setRelationshipSourceId, etc.
   */
  const ephemeralButtons = [
    {
      name: "new",
      icon: "➕",
      angleDeg: -30, // above node
      allowedRoles: ["ADMIN", "EDITOR", "PRIVILEGED_USER"],
      onClick: (event, d) => {
        event.stopPropagation();
        originNodeIdRef.current = d.id;
        onOpenCreateModal?.(d);
      },
    },
    {
      name: "edit",
      icon: "✏",
      angleDeg: 30, // below node
      allowedRoles: ["ADMIN", "EDITOR"],
      onClick: (event, d) => {
        event.stopPropagation();
        originNodeIdRef.current = d.id;
        onOpenEditModal?.(d);
      },
    },
    {
      name: "remove",
      icon: "🗑",
      angleDeg: 90, // right side
      allowedRoles: ["ADMIN", "EDITOR"],
      onClick: (event, d) => {
        event.stopPropagation();
        if (window.confirm(`Delete node "${d.name}"?`)) {
          handleDeleteNode?.(d.id);
        }
      },
    },
    {
      name: "createRel",
      icon: "🔗",
      angleDeg: 150, // left side
      allowedRoles: ["ADMIN", "EDITOR"],
      onClick: (event, d) => {
        event.stopPropagation();
        // Use a functional update if you want to log old vs. new
        // setRelationshipSourceId(prev => {
        //   console.log("Updating relationshipSourceId from", prev, "to", d.id);
        //   return d.id;
        // });
        console.log("Setting relationship source to", d.id);
        relationshipSourceRef.current = d.id;
        console.log("relationshipSourceRef.current", relationshipSourceRef.current);

      },
    },
  ];

  // d) For each ephemeral button => append a <text> element
  ephemeralButtons
  .filter((btn) => {
    // If no allowedRoles property, assume it's allowed for all
    if (!btn.allowedRoles) return true;
    // Otherwise check user.role
    return btn.allowedRoles.includes(user?.role);
  })
  .forEach((btn) => {
    buttonGroupEnter
      .append("text")
      .attr("class", "ephemeral-icon")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .attr("font-size", 14)
      .attr("fill", "#ddd")
      .attr("cursor", "pointer")
      .attr("x", function (d) {
        const radius = d.id === initialNodeId
          ? 60
          : (d.id === rootNodeId ? 50 : 40);
        const offset = radius + 12;
        const rad = (btn.angleDeg * Math.PI) / 180;
        return offset * Math.cos(rad);
      })
      .attr("y", function (d) {
        const radius = d.id === initialNodeId
          ? 60
          : (d.id === rootNodeId ? 50 : 40);
        const offset = radius + 12;
        const rad = (btn.angleDeg * Math.PI) / 180;
        return offset * Math.sin(rad);
      })
      .text(btn.icon)
      .on("click", btn.onClick);
  });

  // 4) MERGE => old + new
  const nodeMerged = nodeEnter.merge(node);

  // Remove any old drag events
  nodeMerged.on(".drag", null);

  // Draggable for non-root nodes only
  const draggableNodes = nodeMerged.filter(
    (d) => d.id !== rootNodeId && d.id !== initialNodeId
  );
  draggableNodes.call(
    d3.drag()
      .on("start", (event, d) => {
        if (!simulationRef.current) return;
        if (!event.active) simulationRef.current.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      })
      .on("drag", (event, d) => {
        d.fx = event.x;
        d.fy = event.y;
      })
      .on("end", (event, d) => {
        if (!simulationRef.current) return;
        if (!event.active) simulationRef.current.alphaTarget(0.01);
        // If node not selected => unpin
        if (!selectedNodeIds.includes(d.id)) {
          d.fx = null;
          d.fy = null;
          onSetPinnedPosition?.(d.id, null);
        } else {
          onSetPinnedPosition?.(d.id, { fx: d.fx, fy: d.fy });
        }
      })
  );

  // Style the visible circle
  nodeMerged
    .select(".visible-circle")
    .attr("fill", (d) => {
      
      switch (d.label) {
        case "USER":
          return "#9b59b6";
        case "ARTICLE":
          return "#2ecc71";
        case "COMMENT":
          return "#95a5a6";
        case "USER_REQUEST":
          return "#f1c40f";
        case "CONCEPT":
        default:
          return "black";
      }
    })
    .attr("stroke", (d) => {
      if (!d.alreadyClicked) {
        return "gray";
      }

      // 1) If this is the current relationship source => highlight
      if (relationshipSourceId === d.id) {
        return "limegreen";
      }
      // 2) If selected => yellow
      if (selectedNodeIds.includes(d.id)) {
        return "#fde990";
      }
      // 3) If it’s the initial node => white
      if (d.id === initialNodeId) return "white";
      // 4) If it’s the root => some color
      if (d.id === rootNodeId) return "#f39c12";
      
      // fallback: vary color by d.status
      switch (d.status) {
        case "ACTIVE":
          return "white";
        case "PENDING_VALIDATION":
          return "orange";
        case "DEACTIVATED":
          return "gray";
        default:
          return "white";
      }
    })
    .attr("stroke-width", (d) =>
      // thicker if panel open for this node
      panelOpenNodeId === d.id
        ? 4
        : (d.id === initialNodeId || d.id === rootNodeId)
          ? 3
          : 2
    )
    .style("stroke-dasharray", (d) => {
      switch (d.privacyStatus) {
        case "PUBLIC":
          return null;     // solid
        case "PRIVATE":
          return "2,2";    // dotted
        case "RESTRICTED":
          return "6,3";    // dashed
        default:
          return null;
      }
    });

  // Apply multi-line labels
  reapplyNodeLabels(nodeMerged, {
    wrapTextFn: wrapText,
    initialNodeId,
    rootNodeId,
  });

  // If user taps the SVG background => hide ephemeral icons
  if (svgRef?.current) {
    d3.select(svgRef.current).on("pointerdown.hideIcons", (event) => {
      if (!currentlyOpenGroup) return;
      const isInsideGroup =
        event.composedPath &&
        event.composedPath().includes(currentlyOpenGroup.node());
      if (!isInsideGroup) {
        hideEphemeralIcons(currentlyOpenGroup);
      }
    });
  }
}