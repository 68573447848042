// src/components/AethericAI/DevGraphNavigator/hook/useHandleRelationshipDelete.js
import { useMutation } from "@apollo/client";
import { DELETE_RELATIONSHIP } from "../../../../graphql/Graph/graph_mutations";

export default function useHandleRelationshipDelete() {
  // 1) Define the Apollo mutation
  const [deleteRelationshipMutation, { data, error, loading }] = useMutation(DELETE_RELATIONSHIP);

  // 2) Define a callback that calls the mutation
  const handleDeleteRelationship = async (relId) => {
    if (!relId) return;

    try {
      const res = await deleteRelationshipMutation({
        variables: {
          id: relId,
        },
      });
      console.log("[useHandleRelationshipDelete] Deleted relationship =>", res.data);
      // If you want to do local updates, you can do them here or rely on re-fetch 
      // or the “update” parameter of useMutation.

    } catch (err) {
      console.error("[useHandleRelationshipDelete] Error deleting relationship =>", err);
    }
  };

  // 3) Return the callback plus any metadata if you want
  return {
    handleDeleteRelationship,
    deleteRelationshipData: data,
    deleteRelationshipError: error,
    deleteRelationshipLoading: loading,
  };
}