// src/components/AethericAI/DevGraphNavigator/useD3Graph.js
import { useRef, useEffect, useCallback, useState } from "react";
import * as d3 from "d3";
import { initializeSVG } from "../graph/initializeSVG";
import { updateLinks } from "../graph/updateLinks";
import { updateNodes } from "../graph/updateNodes";
import { initializeOrUpdateSimulation } from "../graph/initializeSimulation";
import { ticked } from "../graph/ticked";
import { zoomToNode as realZoomToNode } from "../graph/zoomToNode";

import { useElementSize } from "./useElementSize";


export const useD3Graph = ({
  user,
  graphData,
  // width = 800,
  // height = 600,
  initialNode,
  rootNode,
  selectedNodes = [],
  panelOpenNodeId,
  onNodeClick,
  onNodeDoubleClick,
  pinnedPositions,
  setPinnedPositions,
  handleCreateNode,
  handleUpdateNode,
  handleDeleteNode,
  onOpenEditModal,
  onOpenCreateModal,
  originNodeIdRef,
  relationshipSourceId,
  setRelationshipSourceId,
  handleDeleteRelationship,
  relationshipSourceRef,
}) => {
  // This will point to the actual <svg> DOM node once initialized
  const svgRef = useRef(null);       
  const simulationRef = useRef(null);

  const { width, height } = useElementSize(svgRef);

  // For unpinning old root node
  const previousRootIdRef = useRef(rootNode.id);
  const [initialNodeId] = useState(initialNode?.id);

  const tickedCallback = useCallback(() => {
    ticked(svgRef);
  }, [svgRef]);

  // Manage pinned positions
  const handleSetPinnedPosition = useCallback(
    (nodeId, pinned) => {
      setPinnedPositions((prev) => {
        const next = { ...prev };
        if (!pinned) delete next[nodeId];
        else next[nodeId] = pinned;
        return next;
      });
    },
    [setPinnedPositions]
  );

  // Effect => sets up the SVG, draws nodes/links, runs force simulation
  useEffect(() => {
    if (!graphData?.nodes?.length) return;

    // If no <svg> node yet => skip until we have a ref
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);

    // measure width/height from boundingClientRect if you like
    let measuredWidth = width;
    let measuredHeight = height;
    {
      const rect = svgRef.current.getBoundingClientRect();
      measuredWidth = rect.width;
      measuredHeight = rect.height;
    }

    // If there's no ".graph-group", we haven't inited => call initializeSVG
    if (!svg.select(".graph-group").size()) {
      initializeSVG(svgRef, svg); 
      // => sets svgRef.current = <svg> DOM node
      // => sets svgRef.current.__zoomBehavior = zoom
    }

    const svgGroup = svg.select(".graph-group");
    const linksLayer = svgGroup.select(".links-layer");
    const nodesLayer = svgGroup.select(".nodes-layer");

    // Pre-assign pinned positions
    graphData.nodes.forEach((d) => {
      if (d.id !== initialNodeId) {
        const pinned = pinnedPositions?.[d.id];
        d.fx = pinned?.fx ?? null;
        d.fy = pinned?.fy ?? null;
      }
    });

    // Draw links
    updateLinks({
      user,
      linksLayer,
      linksData: graphData.links,
      handleDeleteRelationship,
      svgRef,
    });

    // Draw nodes
    updateNodes({
      user,
      nodesLayer,
      nodesData: graphData.nodes,
      initialNodeId,
      rootNodeId: rootNode.id,
      selectedNodeIds: selectedNodes.map((n) => n.id),
      panelOpenNodeId,
      onClick: onNodeClick,
      onDoubleClick: onNodeDoubleClick,
      simulationRef,
      onSetPinnedPosition: handleSetPinnedPosition,
      handleCreateNode,
      handleUpdateNode,
      handleDeleteNode,
      onOpenEditModal,
      onOpenCreateModal,
      originNodeIdRef,
      relationshipSourceId,
      setRelationshipSourceId,
      relationshipSourceRef,
    });

    // Force simulation
    initializeOrUpdateSimulation({
      simulationRef,
      nodes: graphData.nodes,
      links: graphData.links,
      rootId: rootNode.id,
      initialNodeId,
      previousRootIdRef,
      ticked: tickedCallback,
      measuredWidth,
      measuredHeight,
      zoomToNodeCallback,
    });

    return () => {
      if (simulationRef.current) simulationRef.current.stop();
    };
  }, [
    graphData,
    rootNode.id,
    selectedNodes,
    panelOpenNodeId,
    pinnedPositions,
    onNodeClick,
    onNodeDoubleClick,
    handleSetPinnedPosition,
    width,
    height,
    tickedCallback,
    initialNodeId,
    handleDeleteRelationship,
  ]);

  // Clear sim method
  const clearSimulation = useCallback(() => {
    if (simulationRef.current) {
      simulationRef.current.stop();
      simulationRef.current = null;
    }
  }, []);

  // Zoom to node => calls realZoomToNode with “Option A” signature
  const zoomToNodeCallback = useCallback(
    (nodeId, scaleFactor = 1) => {
      if (!graphData?.nodes?.length) return;

      // Now pass the arguments to the "Option A" signature:
      // realZoomToNode(svgRef, graphData, nodeId, scaleFactor, width, height)
      realZoomToNode(
        svgRef, 
        graphData, 
        nodeId, 
        scaleFactor, 
        width, 
        height
      );
    },
    [graphData, width, height]
  );

  return {
    svgRef, // The <svg> DOM node
    zoomToNode: zoomToNodeCallback,
    clearSimulation,
  };
};