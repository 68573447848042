import React, { useEffect, useRef } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

export default function AiHeader({
  messages = [],
  open = false,
  onClose,
}) {
  // The scrollable container
  const scrollableRef = useRef(null);

  // A ref for the *last* message (newest) so we can see its offsetTop
  const lastMessageRef = useRef(null);

  // On any messages change, align the container’s top with the last message’s top
  useEffect(() => {
    if (!open || !scrollableRef.current || !lastMessageRef.current) return;

    // The container
    const container = scrollableRef.current;

    // The newest message element
    const lastMsgElem = lastMessageRef.current;

    // Scroll the container so that the top of lastMsgElem is at the top of container
    container.scrollTop = lastMsgElem.offsetTop;
  }, [messages, open]);

  if (!open || messages.length === 0) return null;

  const handleCloseClick = () => {
    onClose?.();
  };

  return (
    <div className="w-full bg-black text-white p-3 relative z-80">
      {/* Close (X) button */}
      <button
        onClick={handleCloseClick}
        className="
          absolute top-2 right-2 text-gray-400
          hover:text-white focus:outline-none
        "
        aria-label="Close AI Message"
      >
        <XMarkIcon className="h-4 w-4" />
      </button>

      {/* Scrollable container */}
      <div
        ref={scrollableRef}
        className="max-h-32 overflow-y-auto pr-6 text-sm leading-relaxed whitespace-pre-wrap"
      >
        {messages.map((msg, index) => {
          // For the last message, attach the ref
          const isLast = index === messages.length - 1;
          return (
            <div
              key={index}
              ref={isLast ? lastMessageRef : null}
              className="mb-2"
            >
              {msg}
            </div>
          );
        })}
      </div>
    </div>
  );
}